<template>
  <loading v-if="isLoading" />
  <error v-else-if="isError" />
  <content-not-view v-else-if="!subPermission.index" />

  <div v-else>
    <b-card>
      <div class="custom-search d-flex justify-content-between">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">{{ $t("g.searchLabel") }}</label>
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('g.searchPlaceholder')"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
        <div>
          <b-button
            :to="{
              name: 'ViewServiceRequest',
              params: { id: currentId },
            }"
            variant="primary"
            >{{ $t("g.backToServiceRequest") }}</b-button
          >
        </div>
      </div>

      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        styleClass="vgt-table condensed"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-column" slot-scope="props">
          <span
            v-if="props.column.label === 'isTechEvaluationReportAccepted'"
            class="text-nowrap"
          >
            {{ $t("g.isTechEvaluationReportAccepted") }}
          </span>
          <span
            v-else-if="props.column.label === 'techEvaluationReport'"
            class="text-nowrap"
          >
            {{ $t("g.techEvaluationReport") }}
          </span>
          <span
            v-else-if="props.column.label === 'techEvaluationRejectionReason'"
            class="text-nowrap"
          >
            {{ $t("g.techEvaluationRejectionReason") }}
          </span>
          <span
            v-else-if="props.column.label === 'submitDate'"
            class="text-nowrap"
          >
            {{ $t("g.submitDate") }}
          </span>

          <span v-else-if="props.column.label === 'action'" class="text-nowrap">
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>
        <template slot="table-row" slot-scope="props">
          <span
            v-if="
              props.column.field === 'is_tech_evaluation_report_accepted_row'
            "
            class="text-nowrap"
          >
            {{
              props.row.is_tech_evaluation_report_accepted
                ? $t("g.acception")
                : $t("g.rejection")
            }}
          </span>
          <span v-if="props.column.field === 'techEvaluationReport'" class="text-nowrap">
            <a
              :href="props.row.tech_evaluation_report ? props.row.tech_evaluation_report.path : null"
              class="text-primary"
              target="_blank"
            >
              {{ $t("g.viewDocument") }}
            </a>
          </span>
          <span
            v-if="props.column.field === 'tech_evaluation_rejection_reason_row'"
            class="text-nowrap"
          >
            {{
              props.row.tech_evaluation_rejection_reason_row
                ? props.row.tech_evaluation_rejection_reason_row
                : $t("g.There is no reason to rejection")
            }}
          </span>
          <span
            v-if="props.column.field === 'submit_date_row'"
            class="text-nowrap"
          >
            {{ format(props.row.submit_date) }}
          </span>
          <!-- <span v-if="props.column.field === 'action'">
            <span>
              <b-avatar
                size="32"
                variant="light-primary"
                v-if="subPermission.show"
                :to="{
                  name: 'ViewTechEvaluationsReviews',
                  params: { id: currentId, techId: props.row.id },
                }"
              >
                <feather-icon icon="EyeIcon" size="18" />
              </b-avatar>
            </span>
          </span> -->

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t("g.pagelength") }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                {{ $t("g.of") }} {{ props.total }}
                {{ $t("g.pageText2") }}
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import formatDate from "@/composables/format-date/format-date";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BButton,
    Loading,
    Error,
    ContentNotView,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "isTechEvaluationReportAccepted",
          field: "is_tech_evaluation_report_accepted_row",
        },
        {
          label: "techEvaluationReport",
          field: "techEvaluationReport",
        },
        {
          label: "techEvaluationRejectionReason",
          field: "tech_evaluation_rejection_reason_row",
        },
        {
          label: "submitDate",
          field: "submit_date_row",
        },
        // {
        //   label: "action",
        //   field: "action",
        // },
      ],
      rows: [],
      searchTerm: "",
      currentId: null,
      format: formatDate,
      subPermission: {},
      isLoading: true,
      isError: null,
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  beforeMount() {
    this.currentId = this.$route.params.id;
    this.$http
      .get(`admin/serviceRequests/${this.currentId}/techEvaluationsReviews`)
      .then((res) => {
        this.rows = res.data.data

        setTimeout(() => {
          this.isLoading = false;
          this.isError = false;
          store.dispatch("GET_PERMISSION", "techEvaluationsReviews");
          this.subPermission = store.state.permissions.sub;
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        this.isLoading = false;
        this.isError = true;
      });
  },

  methods: {
    deleteTechEvaluationsReviews(id) {
      this.rows = this.rows.filter((item) => item.id !== id);
    },

    deleteAlert(id) {
      this.$swal({
        title: this.$t("g.areYouSure?"),
        text: this.$t("g.youWontBeAbleToRevertThis"), // You won't be able to revert this!
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("g.yesDeleteIt"), // "Yes, delete it!" ,
        cancelButtonText: this.$t("g.cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            icon: "success",
            title: this.$t("g.deleted"), //"Deleted!"
            text: this.$t("g.yourFileHasBeenDeleted"), //"Your file has been deleted."
            customClass: {
              confirmButton: "btn btn-success",
            },
          });

          this.$http
            .delete(
              `admin/serviceRequests/${this.currentId}/techEvaluationsReviews/${this.tectEvaluationId}/improveRequests/${id}`
            )
            .then((res) => {
              this.deleteTechEvaluationsReviews(id);
            })
            .catch((err) => console.log(err));
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
